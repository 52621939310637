import { Gender } from "@multimediallc/gender-utils"
import { titleCase } from "@multimediallc/web-utils"
import { i18n } from "../../../common/translation"
import { currentSiteSettings } from "../../siteSettings"
import { LinkComponent } from "./link"
import { MetaComponent } from "./meta"
import type { LinkProps, LinkState } from "./link"
import type { MetaProps, MetaState } from "./meta"


export class RoomlistMetaUpdate {
    private canonicalLinkComponent?: LinkComponent
    private hreflangComponents: LinkComponent[]
    private mediaLinkComponent?: LinkComponent
    private rssLinkComponents: LinkComponent[]
    private metaTitleComponent?: MetaComponent
    private metaContentCardDescriptionComponent?: MetaComponent
    private metaDescriptionComponent?: MetaComponent
    private metaKeywordsComponent?: MetaComponent
    private metaURLComponent?: MetaComponent
    private rssAppendAfterElement?: HTMLElement

    constructor() {
        this.replaceTags()
        this.findRssAppendAfterElement()
    }

    private getMetaTitle(gender: Gender, premium: boolean, hashtag: string): string {
        let titleString = ""
        const siteName = titleCase(currentSiteSettings.siteName)
        if (premium) {
            titleString = i18n.metaTitlePremium(siteName)
        } else if (hashtag !== "") {
            titleString = i18n.metaTitleHashtag(siteName, titleCase(hashtag))
        } else if (gender === Gender.Female) {
            titleString = i18n.metaTitleFemale(siteName)
        } else if (gender === Gender.Male) {
            titleString = i18n.metaTitleMale(siteName)
        } else if (gender === Gender.Couple) {
            titleString = i18n.metaTitleCouples(siteName)
        } else if (gender === Gender.Trans || gender === Gender.OldTrans) {
            titleString = i18n.metaTitleTrans(siteName)
        } else {
            titleString = i18n.metaTitleDefault(siteName)
        }
        return titleString
    }

    private findRssAppendAfterElement(): void {
        const mediaLink = document.head.querySelector<HTMLElement>("[media='only screen and (max-width: 640px)']")
        this.rssAppendAfterElement = mediaLink !== null ? mediaLink : document.head.querySelector<HTMLElement>("title") ?? undefined
    }

    private getMetaContentCardDescription(gender: Gender, premium: boolean, hashtag: string): string {
        let descriptionString = ""
        if (premium) {
            descriptionString = i18n.metaContentCardDescriptionPremium
        } else if (hashtag  !== "") {
            descriptionString = i18n.metaContentCardDescriptionHashtag(titleCase(hashtag))
        } else if (gender === Gender.Female) {
            descriptionString = i18n.metaContentCardDescriptionFemale
        } else if (gender === Gender.Male) {
            descriptionString = i18n.metaContentCardDescriptionMale
        } else if (gender === Gender.Couple) {
            descriptionString = i18n.metaContentCardDescriptionCouples
        } else if (gender === Gender.Trans || gender === Gender.OldTrans) {
            descriptionString = i18n.metaContentCardDescriptionTrans
        } else {
            descriptionString = i18n.metaContentCardDescriptionDefault
        }
        return descriptionString
    }

    private getMetaDescription(gender: Gender, premium: boolean): string {
        const descriptionString: string[] = []
        const domainCap = titleCase(window.location.hostname)
        if (gender === Gender.Female) {
            descriptionString.push(i18n.metaDescriptionFemale(domainCap))
        } else if (gender === Gender.Male) {
            descriptionString.push(i18n.metaDescriptionMale(domainCap))
        } else if (gender === Gender.Couple) {
            descriptionString.push(i18n.metaDescriptionCouples(domainCap))
        } else if (gender === Gender.Trans || gender === Gender.OldTrans) {
            descriptionString.push(i18n.metaDescriptionTrans(domainCap))
        } else {
            descriptionString.push(i18n.metaDescriptionDefault)
        }
        if (premium) {
            descriptionString.push(i18n.metaDescriptionPremium)
        }
        return descriptionString.join("\n")
    }

    private getMetaKeywords(gender: Gender, premium: boolean, hashtag: string): string {
        const keywordString: string[] = []
        if (hashtag  !== "") {
            keywordString.push(i18n.metaKeywordsHashtag(hashtag))
        }
        if (gender === Gender.Female) {
            keywordString.push(i18n.metaKeywordsFemale)
        } else if (gender === Gender.Male) {
            keywordString.push(i18n.metaKeywordsMale)
        } else if (gender === Gender.Couple) {
            keywordString.push(i18n.metaKeywordsCouple)
        } else if (gender === Gender.Trans || gender === Gender.OldTrans) {
            keywordString.push(i18n.metaKeywordsTrans)
        } else if (!premium) {
            keywordString.push(i18n.metaKeywordsDefault)
        }
        if (premium) {
            keywordString.push(i18n.metaKeywordsPremium)
        }
        return keywordString.join(" ")
    }

    private getNewURLWithCurrentPathAndSearch(absoluteURL: string): string {
        // Returns absoluteURL with current pathname and params
        const newUrl = new URL(absoluteURL)
        newUrl.pathname = window.location.pathname
        newUrl.search = window.location.search
        return newUrl.toString()
    }

    private createRssLinks(gender: Gender, premium: boolean): void {
        this.removeRssLinks(this.rssLinkComponents.map((cmp) => { return cmp.element }))
        this.rssLinkComponents = []
        if (premium || gender === Gender.All) {
            const premiumLinkProps: LinkProps = {
                rel: "alternate",
                type: "application/rss+xml",
                href: this.getRssLinkHref(gender, premium),
                title: this.getRssLinkTitle(gender, premium),
            }
            const premiumRssLink = new LinkComponent(premiumLinkProps)
            this.rssLinkComponents.push(premiumRssLink)
            if (this.rssAppendAfterElement !== undefined) {
                this.rssAppendAfterElement.insertAdjacentElement("afterend", premiumRssLink.element)
            }
        }
        if (gender !== Gender.All) {
            const genderLinkProps: LinkProps = {
                rel: "alternate",
                type: "application/rss+xml",
                href: this.getRssLinkHref(gender, false),
                title: this.getRssLinkTitle(gender, false),
            }
            const genderLink = new LinkComponent(genderLinkProps)
            this.rssLinkComponents.push(genderLink)
            if (this.rssAppendAfterElement !== undefined) {
                this.rssAppendAfterElement.insertAdjacentElement("afterend", genderLink.element)
            }
        }
    }

    private removeRssLinks(links: HTMLLinkElement[]): void {
        for (const link of links) {
            link.remove()
        }
    }

    private getRssLinkHref(gender: Gender, premium: boolean): string {
        const newParams = new URLSearchParams()
        if (gender !== Gender.All) {
            newParams.set("gender", gender)
        }
        if (premium) {
            newParams.set("premium", "1")
        }
        return `/feed/latest/?${newParams.toString()}`
    }

    private getRssLinkTitle(gender: Gender, premium: boolean): string {
        let camCategory = i18n.featuredText
        const siteName = titleCase(currentSiteSettings.siteName)
        if (premium) {
            camCategory = i18n.premiumShowsCaps
        } else if (gender === Gender.Female) {
            camCategory = i18n.femaleText
        } else if (gender === Gender.Male) {
            camCategory = i18n.maleText
        } else if (gender === Gender.Couple) {
            camCategory = i18n.coupleText
        } else if (gender === Gender.Trans) {
            camCategory = i18n.transText
        }
        return i18n.rssLinkTitle(camCategory, siteName)
    }

    private replaceTags(): void {
        // Replace tags that are updated on navigation with HtmlComponents
        this.canonicalLinkComponent = this.getLinkComponentFromSelector("[rel='canonical']")
        this.hreflangComponents = []
        this.rssLinkComponents = []
        const hreflangLinks = document.head.querySelectorAll<HTMLLinkElement>("[hreflang]")
        for (const link of hreflangLinks) {
            const newLinkTag = this.getLinkComponentFromReplacedLinkTag(link)
            if (newLinkTag !== undefined) {
                this.hreflangComponents.push(newLinkTag)
            }
        }
        this.metaDescriptionComponent = this.getMetaComponentFromSelector("[name='description']")
        this.metaKeywordsComponent = this.getMetaComponentFromSelector("[name='keywords']")
        this.mediaLinkComponent = this.getLinkComponentFromSelector("[media='only screen and (max-width: 640px)']")
        const rssLinks = document.head.querySelectorAll<HTMLLinkElement>("[type='application/rss+xml']")
        for (const link of rssLinks) {
            const newRssLink = this.getLinkComponentFromReplacedLinkTag(link)
            if (newRssLink !== undefined) {
                this.rssLinkComponents.push(newRssLink)
            }
        }
        this.metaTitleComponent = this.getMetaComponentFromSelector("[property='og:title']")
        this.metaContentCardDescriptionComponent = this.getMetaComponentFromSelector("[property='og:description']")
        this.metaURLComponent = this.getMetaComponentFromSelector("[property='og:url']")
    }

    private getMetaComponentFromSelector(selector: string): MetaComponent | undefined {
        const metaElement = document.head.querySelector<HTMLMetaElement>(selector)
        if (metaElement === null) {
            return undefined
        }
        const props: MetaProps = {
            name: metaElement.name,
            property: metaElement.getAttribute("property") ?? undefined,
            content: metaElement.content,
        }
        const newMetaTag = new MetaComponent(props)
        metaElement.replaceWith(newMetaTag.element)
        return newMetaTag
    }

    private getLinkComponentFromSelector(selector: string): LinkComponent | undefined {
        const linkElement = document.head.querySelector<HTMLLinkElement>(selector)
        return this.getLinkComponentFromReplacedLinkTag(linkElement)
    }

    private getLinkComponentFromReplacedLinkTag(linkElement: HTMLLinkElement | null): LinkComponent | undefined {
        if (linkElement === null) {
            return undefined
        }
        const props: LinkProps = {
            href: linkElement.href,
            hreflang: linkElement.hreflang,
            rel: linkElement.rel,
            title: linkElement.title,
            media: linkElement.media,
            type: linkElement.type,
        }
        const newLinkTag = new LinkComponent(props)
        linkElement.replaceWith(newLinkTag.element)
        return newLinkTag
    }

    public updateTags(gender: Gender, premium: boolean, hashtag: string): void {
        const baseDomain = this.getNewURLWithCurrentPathAndSearch(window.location.href)
        const newLangParams = new URLSearchParams(window.location.search)
        const currentLangUrl = new URL(window.location.href)
        this.updateLinkComponent(this.canonicalLinkComponent, { href: baseDomain })
        for (const link of this.hreflangComponents) {
            if (link.element.hreflang !== "x-default") {
                newLangParams.set("language", link.element.hreflang)
                currentLangUrl.search = newLangParams.toString()
            }
            this.updateLinkComponent(link, { href: currentLangUrl.toString() })
        }
        this.updateMetaComponent(this.metaDescriptionComponent, { content: this.getMetaDescription(gender, premium) })
        this.updateMetaComponent(this.metaKeywordsComponent, { content: this.getMetaKeywords(gender, premium, hashtag) })
        this.updateLinkComponent(this.mediaLinkComponent, { href: this.getNewURLWithCurrentPathAndSearch(window.location.href) })
        this.createRssLinks(gender, premium)
        this.updateMetaComponent(this.metaTitleComponent, { content: this.getMetaTitle(gender, premium, hashtag) })
        this.updateMetaComponent(this.metaContentCardDescriptionComponent, { content: this.getMetaContentCardDescription(gender, premium, hashtag) })
        this.updateMetaComponent(this.metaURLComponent, { content: baseDomain })
    }

    private updateMetaComponent(component: MetaComponent | undefined, value: MetaState): void {
        if (component !== undefined) {
            component.setState(value)
        }
    }

    private updateLinkComponent(component: LinkComponent | undefined, value: LinkState): void {
        if (component !== undefined) {
            component.setState(value)
        }
    }
}
