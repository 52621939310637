import { PRIVATE_PRICES_FILTER_KEY, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../../../common/defui/htmlComponent";
import { ignoreMetaClick } from "../../../../../common/DOMutils";
import { isPrivateSpyBadgesActive } from "../../../../../common/featureFlagUtil"
import { addPageAction } from "../../../../../common/newrelic";
import { i18n } from "../../../../../common/translation"
import { dom } from "../../../../../common/tsxrender/dom"
import { PRIVATE_PRICE_OPTIONS, PRIVATE_PRICES_MAPPING } from "../constants"
import { FilterSectionBase } from "./filterSectionBase"
import type { FilterSectionProps } from "./filterSectionBase"
import type { PrivatePrices } from "@multimediallc/cb-roomlist-prefetch"

export class PrivatePriceSection extends HTMLComponent<HTMLDivElement> {
    protected createElement(props: object): HTMLDivElement {
        const mappings = PRIVATE_PRICES_MAPPING
        return <div className="filterSection" data-testid="filter-private-show-tokens-per-minute-section">
            <div className="filterSectionHeader"
                 data-testid="filter-private-show-tokens-per-minute-header">{i18n.privateTksPerMinCAPS}</div>
            <div className="filterSectionOptions">
                {[...mappings.keys()].map(k => (
                    <a
                        bind={{
                            className: () => `filterOption ${UrlState.current.state.privatePrices?.includes(k) ?? false ? "active" : ""}`,
                            href: () => UrlState.current.getURLForPartialState({ privatePrices: [k]}),
                        }}
                        onClick={(e: MouseEvent) => ignoreMetaClick(e, () => {
                            const active = UrlState.current.state.privatePrices?.includes(k) ?? false;
                            if (isPrivateSpyBadgesActive()) {
                                const prices = UrlState.current.state.privatePrices ?? []
                                const newPrices = active ? prices?.filter((r: string) => r !== k) : [...prices, k]
                                newPrices?.sort()
                                UrlState.current.setPartialState({ privatePrices: newPrices })
                            } else {
                                if (active) {
                                    UrlState.current.clearStateKeys(["privatePrices"])
                                } else {
                                    UrlState.current.setPartialState({ privatePrices: [k]})
                                }
                            }
                            addPageAction("HmpgFilterOptionClicked", {
                                "category": "private_prices",
                                "value": k,
                                "active": active,
                            })
                        })}
                        title={mappings.get(k)}
                        data-testid="filter-private-show-tokens-per-minute-item"
                    >{mappings.get(k)}</a>
                ))}
            </div>
        </div>
    }
}

export class PrivatePriceFilterSection extends FilterSectionBase {

    protected createElement(props: FilterSectionProps): HTMLDivElement {
        this.filterKey = PRIVATE_PRICES_FILTER_KEY
        this.sectionOptions = PRIVATE_PRICE_OPTIONS
        this.sectionTitle = i18n.privateTksPerMinCAPS
        this.isSingleSelect = !isPrivateSpyBadgesActive()
        return super.createElement(props)
    }

    protected optionIsActive(queryParamValue: string): boolean {
        const currentValue = UrlState.current.state["privatePrices"]
        if (currentValue === undefined) {
            return false
        }
        return currentValue.includes(queryParamValue as PrivatePrices)
    }
}
