import {
    DEFAULT_MAX_AGE, DEFAULT_MIN_AGE, FILTER_PANEL_KEYS,
    shouldShowHomepageFilters, ShowType, UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { SPOKEN_LANGUAGES_MAP } from "@multimediallc/web-utils"
import { HTMLComponent } from "../../../../common/defui/htmlComponent"
import { isFilterInPathActive } from "../../../../common/featureFlagUtil"
import { addPageAction } from "../../../../common/newrelic"
import { dom } from "../../../../common/tsxrender/dom"
import { PRIVATE_PRICES_MAPPING, REGION_MAPPING, ROOM_SIZE_MAPPING } from "./constants"
import { FilterLabel } from "./filterLabel"
import { FiltersClearButton } from "./filtersClearButton"
import type { IURLState, PrivatePrices, Region } from "@multimediallc/cb-roomlist-prefetch"

export interface FilterLabelSectionProps {
    onClearAll?: () => void
    onRemoval?: () => void
}

interface LanguageLabel {
    code: string
    label: FilterLabel
}

export class FilterLabelSection extends HTMLComponent<HTMLDivElement> {
    protected clearButton: FiltersClearButton
    private tagLabel?: FilterLabel
    private languageLabel?: LanguageLabel
    private props: FilterLabelSectionProps
    private labels: FilterLabel[] = []

    protected createElement(props: FilterLabelSectionProps): HTMLDivElement {
        this.props = props
        return <div className="filterLabelSection" bind={{
                        display: () => shouldShowHomepageFilters() ? "block" : "none",
                        style: () => {return { marginTop: (isFilterInPathActive() && this.isFollowedPage() ? "10px" : "") }},
                }}>
            <FiltersClearButton
                classRef={(c) => { this.clearButton = c }}
                onClearAll={() => {
                    if (isFilterInPathActive()) {
                        addPageAction("HmpgFiltersClearButtonClicked")
                        const toClearKeys: (keyof IURLState)[] = [...FILTER_PANEL_KEYS, "keywords", "page", "pageb"]
                        UrlState.current.clearStateKeys(toClearKeys)
                        this.updateState()
                    } else {
                        props.onClearAll?.()
                }}}
            />
        </div>
    }

    protected initData(props: FilterLabelSectionProps): void {
        super.initData(props)

        if (isFilterInPathActive()) {
            UrlState.current.listen([...FILTER_PANEL_KEYS],
                () => {
                    this.updateState()
                }, this.element)
        } else {
            this.updateTagFilterLabel()
            this.updateLanguageFilterLabel()
            UrlState.current.listen(["tags"], () => {
                this.updateTagFilterLabel()
            }, this.element)
            UrlState.current.listen(["spokenLanguages"], () => {
                this.updateLanguageFilterLabel()
            }, this.element)
        }
    }

    private updateTagFilterLabel(): void {
        const currentTag = UrlState.current.state.tags?.[0]
        if (currentTag !== undefined) {
            if (this.tagLabel !== undefined) {
                if (this.tagLabel.getTitle().slice(1) !== currentTag) {
                    this.replaceTagLabel(currentTag)
                }
            } else {
                this.createTagLabel(currentTag)
            }
        } else if (this.tagLabel !== undefined) {
            this.tagLabel.element.remove()
            delete this.tagLabel
        }
    }

    private refresh(state: IURLState): void {
        const labels = []
        if ((state.regions ?? []).length > 0) {
            REGION_MAPPING.forEach((region: string, key: Region) => {
                if ((state.regions ?? []).includes(key)) {
                    labels.push(new FilterLabel({
                        title: region,
                        onRemoval: () => this.removeFilter({
                            regions: state.regions?.filter((r: string) => r !== key),
                        }),
                    }))
                }
            })
        }
        if (state.roomSize !== undefined) {
            labels.push(new FilterLabel({
                title: ROOM_SIZE_MAPPING.get(state.roomSize) ?? "",
                onRemoval: () => this.removeFilter({ roomSize: undefined }),
            }))
        }
        if ((state.tags ?? []).length > 0) {
            labels.push(new FilterLabel({
                title: `#${(state.tags ?? [])[0]}`,
                onRemoval: () => this.removeFilter({ tags: []}),
            }))
        }
        if (state.ageMin !== undefined || state.ageMax !== undefined) {
            labels.push(new FilterLabel({
                title: this.getAgeLabelText(state),
                onRemoval: () => this.removeFilter({ ageMin: undefined, ageMax: undefined }),
            }))
        }
        state.privatePrices?.forEach((price: PrivatePrices) => {
            labels.push(new FilterLabel({
                title: PRIVATE_PRICES_MAPPING.get(price) ?? "",
                onRemoval: () => this.removeFilter({
                    privatePrices: state.privatePrices?.filter((r: string) => r !== price),
                }),
            }))
        })
        state.spokenLanguages?.forEach((lang: string) => {
            labels.push(new FilterLabel({
                title: SPOKEN_LANGUAGES_MAP.get(lang) ?? "",
                onRemoval: () => this.removeFilter({
                    spokenLanguages: [],
                }),
            }))
        })
        this.labels.forEach((label) => label.element.remove())
        this.labels = labels
        this.labels.reverse().forEach((label) => this.prependChild(label))
        this.clearButton.showOrHideElement(this.labels.length > 0, "inline-block")
    }

    updateState(): void {
        super.updateState()
        this.refresh(UrlState.current.state)
    }

    private replaceTagLabel(currentTag: string): void {
        this.tagLabel?.element.remove()
        this.createTagLabel(currentTag)
    }

    private createTagLabel(currentTag: string): void {
        this.tagLabel = new FilterLabel({
            title: `#${currentTag}`,
            onRemoval: () => {
                UrlState.current.clearStateKeys(["tags"], false)
                this.props.onRemoval?.()
            },
        })
    }

    private createLanguageLabel(currentSpokenLanguage: string): void {
        const languageDisplayName = SPOKEN_LANGUAGES_MAP.get(currentSpokenLanguage)
        if (languageDisplayName === undefined) {
            return
        }
        this.languageLabel = {
            code: currentSpokenLanguage,
            label: new FilterLabel({
                title: languageDisplayName,
                onRemoval: () => {
                    UrlState.current.clearStateKeys(["spokenLanguages"], false)
                    this.props.onRemoval?.()
                },
            }),
        }
    }

    private updateLanguageFilterLabel(): void {
        const currentSpokenLanguage = UrlState.current.state.spokenLanguages?.[0]
        if (this.languageLabel?.code === currentSpokenLanguage) {
            return
        }
        this.languageLabel?.label.element.remove()
        if (currentSpokenLanguage !== undefined) {
            this.createLanguageLabel(currentSpokenLanguage)
        } else {
            delete this.languageLabel
        }
    }

    private removeFilter(param: Partial<IURLState>): void {
        UrlState.current.setPartialState({ ...param })
    }


    private getAgeLabelText(state: IURLState): string {
        if (state.ageMin === state.ageMax) {
            return (state.ageMin ?? DEFAULT_MIN_AGE).toString()
        } else if (state.ageMax !== undefined && state.ageMax < DEFAULT_MAX_AGE) {
            return `${state.ageMin ?? DEFAULT_MIN_AGE} - ${state.ageMax - 1 ?? DEFAULT_MAX_AGE}`
        } else {
            return `${state.ageMin ?? DEFAULT_MIN_AGE} +`
        }
    }

    private isFollowedPage() {
        return UrlState.current.state.showType === ShowType.FOLLOW || UrlState.current.state.showType === ShowType.FOLLOW_OFFLINE
    }
}
