import {
    FOLLOW_RECOMMENDATIONS_API_URL,
    FOLLOW_RECOMMENDATIONS_PAGE_SIZE,
    getAPIParamsFromURLState,
    HOMEPAGE_KEYS_NO_PAGE,
    isFollowedCams,
    ShowType,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { ListenerGroup } from "../../../common/events";
import { isFilterInPathActive, isRecommendedFollowRoomsActive } from "../../../common/featureFlagUtil"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { getRoomlistCategoryFilters, getRoomlistDynamicFilters } from "./filters/filtersUtil";
import { PaginatedApiRoomList } from "./paginatedApiRoomList"
import { newSpaLoad } from "./spaHelpers"
import type { AdvancedSearchOptions } from "../../advancedSearchOptions"
import type { IURLState } from "@multimediallc/cb-roomlist-prefetch";

export interface IFollowRecommendationsProps {
    animate: boolean
    showLocation: boolean
    advancedSearchOptions?: AdvancedSearchOptions
}

// Roomlist container subclass specifically intended for RcmFlwRm.
export class FollowRecommendationsRoomlistContainer extends HTMLComponent<HTMLDivElement, IFollowRecommendationsProps> {
    private roomlist: PaginatedApiRoomList
    private listeners: ListenerGroup

    protected initData(props: IFollowRecommendationsProps): void {
        this.listeners = new ListenerGroup()
        if (isFilterInPathActive()) {
            UrlState.current.listen([...HOMEPAGE_KEYS_NO_PAGE, "pageType"], (state: IURLState) => {
                this.updateOrHideElement()
            }, this.element)
            this.updateOrHideElement()
        } else {
            if (props.advancedSearchOptions !== undefined) {
                this.listeners.add(props.advancedSearchOptions.regionsChanged.listen(() => this.handleAdvancedSearchOptionChange()))
                this.listeners.add(newSpaLoad.listen(() => {
                    this.updateContainerFilters()
                    this.updateRooms()
                }))
            }
        }
    }

    private isActive(): boolean {
        return isFollowedCams() && isRecommendedFollowRoomsActive()
    }

    private updateOrHideElement(): void {
        // For FltInPath, the recommendations aren't disposed when navigating
        // to non follow pages, thus we update only on the follow page and hide otherwise
        if (UrlState.current.state.showType === ShowType.FOLLOW) {
            this.updateRooms()
        } else {
            this.hideElement()
        }
    }

    private possiblyShowElement(): void {
        if (this.isActive() && this.roomlist.rooms.length > 0) {
            this.showElement()
        } else {
            this.hideElement()
        }
    }

    protected createElement(props: IFollowRecommendationsProps): HTMLDivElement {
        return <div className="followRecommendations roomlist_container">
            <h2>{i18n.recommendedForYou}</h2>
            <PaginatedApiRoomList
                classRef ={(c) => {this.roomlist = c}}
                apiUrl={FOLLOW_RECOMMENDATIONS_API_URL}
                pageSize={FOLLOW_RECOMMENDATIONS_PAGE_SIZE}
                animate={props.animate}
                showLocation={props.showLocation}
            />
        </div>
    }

    protected handleAdvancedSearchOptionChange(): void {
        this.updateContainerFilters()
        this.updateRooms()
    }

    private updateContainerFilters(): void {
        const newCategoryFilters = getRoomlistCategoryFilters()
        const newFilters = getRoomlistDynamicFilters()
        // The offline followed page does not have gender filters
        if (newCategoryFilters[ShowType.FOLLOW_OFFLINE] === true) {
            delete newFilters.genders
        }
        this.roomlist.setCategoryFilters(newCategoryFilters)
        this.roomlist.updateFilters(newFilters, 1)
    }

    private updateRooms(): void {
        const params = isFilterInPathActive() ? getAPIParamsFromURLState(UrlState.current.state) : undefined
        this.roomlist.fetchRooms(
            undefined,
            params,
        ).then(() => {
            this.possiblyShowElement()
        }).catch((err) => {
            error("Failed to load roomlist page after retrying", err)
            this.hideElement()
        })
    }

    public dispose(): void {
        if (!isFilterInPathActive()) {
            this.roomlist.dispose()
            this.listeners.removeAll()
        }
    }
}
