import {
    ALL,
    getCachedHmpgFiltersUrl,
    getCurrentPage,
    getFilterPanelOpenCached,
    getKeywordsFromCurrentURL,
    getPageHashtag,
    getParamsMinMaxAgeFilter,
    getParamsPrivatePriceFilters,
    getParamsRegionFilters,
    getParamsRoomSizeFilter,
    getParamsSpokenLanguagesFilter,
    getRegionCategoryFilter,
    homepageFiltersCurrentlyApplied,
    isFollowedCams,
    isHomepageFiltersActive,
    isOfflineFollowed,
    isPremium,
    isRoomRoomlistSpaActive,
    PageType,
    shouldShowHomepageFilters,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { isiPhone, isiPod } from "@multimediallc/web-utils/modernizr"
import {
    addDeferedEventListenerPoly,
    addEventListenerMultiPoly,
    addEventListenerPoly,
} from "../../../common/addEventListenerPolyfill"
import { Component } from "../../../common/defui/component"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { getEligibleTargetAnchor, getScrollbarWidth } from "../../../common/DOMutils"
import {
    isBlockMetaDataActive,
    isFilterInPathActive,
    isPrivateSpyBadgesActive,
    isRecommendedFollowRoomsActive,
} from "../../../common/featureFlagUtil"
import { Gender, getCurrentGender } from "../../../common/genders"
import { addPageAction } from "../../../common/newrelic"
import { dom } from "../../../common/tsxrender/dom"
import { AdvancedSearchOptions } from "../../advancedSearchOptions"
import { spaPageContext } from "../../interfaces/context"
import { isRoomAnimationEnabled, RoomReload } from "../../roomList"
import { makeResponsive } from "../../ui/responsiveUtil"
import { RoomlistSearchInput } from "../../ui/searchBar/roomlistSearchInput"

import { HashtagTicker } from "../hashtags/hashtagTicker"
import { RemoveHashtag } from "../hashtags/removeHashtag"
import { RoomlistMetaUpdate } from "../head/roomlistMetaUpdate"
import { GendersTabs } from "../header/gendersTabs"
import { MobileSiteNotice } from "../MobileSiteNotice"
import { HomepageFilterButton } from "./filters/filterButton"
import { FilterLabelSection } from "./filters/filterLabelSection"
import { HomepageFilterPanel } from "./filters/filterPanel"
import { isCategoryPage } from "./filters/filtersUtil"
import { cacheHomepageFilters } from "./filters/homepageFiltersUtil"
import { FollowedOnlineOfflineTab } from "./followedOnlineOfflineTab"
import {
    FollowRecommendationsRoomlistContainer,
} from "./followRecommendationsRoomlistContainer"
import { HiddenCamsRoomlistContainer } from "./hiddenCamsRoomlistContainer"
import { HomepageRoomlistContainer } from "./homepageRoomlistContainer"
import {
    INACTIVE_THRESHOLD_MILLISECONDS,
    PREMIUM_REFRESH_SECONDS,
    SECONDARY_PAGE_PARAM,
    SPA_RELOAD_MILLISECONDS,
} from "./spaConstants"
import {
    genderFilterUpdateFromNav,
    getRoomlistPageTitle,
    isRoomRoomlistSpaEligiblePage,
    newSpaLoad,
    ROOMLIST_NAV_ATTRIBUTE,
    spaNavigation,
    useSpaMetaUpdate,
} from "./spaHelpers"
import { bindSpaJoinOverlay } from "./spaJoinOverlay"
import { SubNavHeaderTabs } from "./subNavHeaderTabs"
import type {
    IFollowRecommendationsProps,
} from "./followRecommendationsRoomlistContainer";
import type { IRoomlistContainerProps } from "./homepageRoomlistContainer"
import type { ISubNavHeaderTabsProps } from "./subNavHeaderTabs"
import type { IRoomListDossier } from "../../../entrypoints/roomlist"
import type { IURLState, PrivatePrices, Region } from "@multimediallc/cb-roomlist-prefetch"

const ROOMLIST_MAIN_CONTENT_ID_SELECTOR = "#roomlist_content_wrapper"

export class RoomlistRoot extends HTMLComponent<HTMLDivElement, IRoomListDossier> {
    private props: IRoomListDossier
    private roomlistMetaUpdate?: RoomlistMetaUpdate
    private advancedSearchOptions?: AdvancedSearchOptions
    private genderTabs?: SubNavHeaderTabs
    private hashtagTicker?: HashtagTicker
    private removeHashtagLink?: RemoveHashtag
    private followedOnlineOfflineTab?: FollowedOnlineOfflineTab
    private hmpgFilterButton?: HomepageFilterButton
    private filterLabelSection?: FilterLabelSection
    private filterPanel?: HomepageFilterPanel
    private recommendations?: FollowRecommendationsRoomlistContainer
    private roomlistContainer?: HomepageRoomlistContainer
    private secondaryContainer?: HomepageRoomlistContainer
    private lastUserActivityTimestamp = 0

    private _topSection: Component
    private _mainContentDiv: Component

    protected createElement(props: IRoomListDossier): HTMLDivElement {
        this.props = props
        if (!isFilterInPathActive()) {
            return document.querySelector("#roomlist_root")!
        } else {
            const advancedSearchOptions = new AdvancedSearchOptions(false, true)
            return <div id="roomlist_root" data-testid="room-list">
                <FollowedOnlineOfflineTab />
                <FilterLabelSection classRef={(c) => {this.filterLabelSection = c}} />
                <HomepageRoomlistContainer advancedSearchOptions={advancedSearchOptions}
                    classRef={(c) => { this.roomlistContainer = c }}
                    animate={this.props.animateThumbnails}
                    showLocation={this.props.showLocation}
                    appName={this.props.appName} />
                <HomepageRoomlistContainer advancedSearchOptions={advancedSearchOptions}
                    classRef={(c) => { this.secondaryContainer = c }}
                    animate={this.props.animateThumbnails}
                    showLocation={this.props.showLocation}
                    appName={this.props.appName}
                    isSecondary={true} />
                {isRecommendedFollowRoomsActive() && <FollowRecommendationsRoomlistContainer
                    classRef={(c) => {this.recommendations = c}}
                    animate={this.props.animateThumbnails}
                    showLocation={this.props.showLocation}
                />}
            </div>
        }
    }

    /**
     * INIT METHODS
     */

    protected initUI(props: IRoomListDossier): void {
        super.initUI(props)
        if (!isFilterInPathActive()) {
            this._mainContentDiv = new Component(document.querySelector<HTMLDivElement>(ROOMLIST_MAIN_CONTENT_ID_SELECTOR)!)
        }
        this.initTopSectionNav()
        this.makeRoomListResponsive()

        if (props.isTestbed) { return }  // Nothing else to init if no room cards to show

        if (!useSpaMetaUpdate()) {
            this.roomlistMetaUpdate = new RoomlistMetaUpdate()
        }

        this.bindRoomRefreshTimers()
        this.bindRoomlistSpaHandlers()

        if (!isFilterInPathActive()) {
            // Do initial pre-render cleanup
            this.cleanUrlParams()
            this.removePlaceholders()
            // Initial render for the actual main content
            if (UrlState.current.state.room === undefined) {
                this.handleSpaRouting()
            }
        } else {
            if (!isRoomRoomlistSpaActive()) {
                const buttonContainer = document.querySelector<HTMLDivElement>(".advanced-search-button-container") as HTMLDivElement
                buttonContainer.appendChild(
                    <HomepageFilterButton/>,
                )
            }
            document.querySelector(".homepageFilterPanel")!.replaceWith(
                <HomepageFilterPanel classRef={c => this.filterPanel = c}/>,
            )
            if (!useSpaMetaUpdate()) {
                UrlState.current.listen(ALL, () => {
                    // Update shared page elements from the top down, starting with the document title
                    window.document.title = getRoomlistPageTitle()
                    const hashtag = getPageHashtag() ?? ""
                    // Update Meta tags and Head hrefs.
                    this.roomlistMetaUpdate?.updateTags(getCurrentGender(), isPremium(), hashtag)
                }, this.element)
            }
        }
        if (isRoomRoomlistSpaActive() || isFilterInPathActive()) {
            UrlState.current.listen(["pageType", "showType"], (state: IURLState) => {
                if (state.pageType === PageType.HOME || state.pageType === PageType.ROOM) {
                    this.updateState()
                }
            }, this.element)
            this.updateState()
        }
    }

    updateState(): void {
        if (isFilterInPathActive()) {
            const mainDiv = new Component(document.querySelector<HTMLDivElement>(ROOMLIST_MAIN_CONTENT_ID_SELECTOR)!)
            const isHomepage = UrlState.current.state.pageType === PageType.HOME
            mainDiv.showOrHideElement(isHomepage)
            this.filterPanel?.updateState()
        }
        if (!useSpaMetaUpdate()) {
            // Update shared page elements from the top down, starting with the document title
            window.document.title = getRoomlistPageTitle()
            const hashtag = getPageHashtag() ?? ""
            // Update Meta tags and Head hrefs.
            this.roomlistMetaUpdate?.updateTags(getCurrentGender(), isPremium(), hashtag)
        }
        super.updateState()
    }

    private initTopSectionNav(): void {
        if (isRoomRoomlistSpaActive()) {
            if (!isFilterInPathActive()) {
                this.initTopSectionNavSpa()
            }
            return
        }
        const topSection = new Component(document.querySelector<HTMLDivElement>(".top-section")!)
        const onTabClickHandler = (event: MouseEvent) => {
            this.spaInterceptOnClick(event)
        }
        const enableGenderedLinkUpdates = !this.props.isTestbed
        const subNavProps: ISubNavHeaderTabsProps = {
            enableGenderedLinkUpdates: enableGenderedLinkUpdates,
            // SPA roomlists must handle join overlay differently from other pages
            skipJoinOverlaySetup: true,
            ...!isFilterInPathActive() && enableGenderedLinkUpdates && { onTabClick: onTabClickHandler },
        }
        const genderTabs = isFilterInPathActive() ? new GendersTabs({}) : new SubNavHeaderTabs(subNavProps)
        topSection.addChild(genderTabs)

        if (this.props.showMobileSiteBannerLink === true) {
            const mobileSiteNotice = new MobileSiteNotice()
            // If shown, the mobile site banner should sit *above* the nav elements for visibility
            topSection.element.parentElement?.prepend(mobileSiteNotice.element)
            mobileSiteNotice.show()
        }
        if (!isFilterInPathActive()) {
            this.advancedSearchOptions = new AdvancedSearchOptions(false, true)
            this._topSection = topSection
            this.genderTabs = genderTabs as SubNavHeaderTabs
        }
    }

    private initTopSectionNavSpa(): void {
        this._topSection = new Component(document.querySelector<HTMLDivElement>(".top-section")!)
        this.genderTabs = SubNavHeaderTabs.getInstance()
        this.advancedSearchOptions = new AdvancedSearchOptions(false, true)
    }

    private makeRoomListResponsive(): void {
        if (!isiPhone() && !isiPod()) {
            // on iPhone body's minWidth is set as width making the screen 500px
            document.body.style.minWidth = `${500 - getScrollbarWidth()}px`
        }

        const content = isFilterInPathActive() ? document.querySelector(ROOMLIST_MAIN_CONTENT_ID_SELECTOR) as HTMLElement : this._mainContentDiv
        makeResponsive(content, 500, 600, [
            { name: "margin-left", min: 4, max: 15 },
            { name: "margin-right", min: 4, max: 15 },
        ])
    }

    private removePlaceholders(): void {
        // Remove temporary placeholders from initial pageload
        const placeholderRoomlistDivs = document.querySelectorAll(".placeholder_roomlist_container")
        placeholderRoomlistDivs.forEach((div) => {
            div.remove()
        })
    }

    private cleanUrlParams(): void {
        // Replace all filter-related URL params with values returned from associated helper fxns.
        // This will both remove any params with invalid values, and replace any duplicated params
        // with a single param containing the one value that will actually be used by the filters.

        const ageParam = getParamsMinMaxAgeFilter()

        // All values that have fallen back to the empty string will get removed
        const queryStringState = UrlState.current.convertStateToUrl({
            pageType: PageType.HOME,
            page: getCurrentPage(),
            pageb: getCurrentPage(SECONDARY_PAGE_PARAM),
            ageMin: ageParam?.[0],
            ageMax: ageParam?.[1],
            privatePrices: this.firstOrAllPrivatePrices(),
            regions: getParamsRegionFilters()?.split(",") as Region[] ?? undefined,
            roomSize: getParamsRoomSizeFilter(),  // this does not have paths for individual selections
            spokenLanguages: getParamsSpokenLanguagesFilter(),
            keywords: getKeywordsFromCurrentURL() ?? undefined,
        })

        const currentUrl = new URL(window.location.href)
        currentUrl.search = (new URL(queryStringState)).search
        UrlState.current.replaceUrl(currentUrl)
    }

    private firstOrAllPrivatePrices(): PrivatePrices[] {
        // Limit private price to first filter values unless PvtSpyBdgs is active.
        const multiPrivatePrices = getParamsPrivatePriceFilters() ?? []
        return isPrivateSpyBadgesActive() ? multiPrivatePrices : multiPrivatePrices.slice(0, 1)
    }

    /**
     * ROUTING + NAVIGATION-EVENT-BINDING METHODS
     */

    private bindRoomlistSpaHandlers(): void {
        if (!isRoomRoomlistSpaActive()) {
            bindSpaJoinOverlay()
        }
        if (!isFilterInPathActive()) {
            if (!isRoomRoomlistSpaActive()) {
                addEventListenerPoly("click", document, (event: MouseEvent) => {
                    this.spaInterceptOnClick(event, true)
                })
            }
            spaNavigation.listen((event: MouseEvent) => {
                this.spaInterceptOnClick(event)
            })
            addEventListenerPoly("popstate", window, () => {
                // The url has already been updated at this point.  So cache the homepage filters state
                // to prevent an outdated cache value from being applied later on during spa routing
                const isRmRmlistActive = isRoomRoomlistSpaActive()
                if (!isRmRmlistActive || (isRmRmlistActive && UrlState.current.state.room === undefined)) {
                    cacheHomepageFilters()
                    this.handleSpaRouting()
                }
                this.setUserActivityTimestamp()
            })
        }

        // Pageshow is also fired on initial document load just after the `load` event, but since the entrypoint code runs
        // _during_ load, we need to ignore it the very first time it fires or we end up loading the initial roomlist twice.
        // It would be nicer to just check event.persisted, but unfortunately that's been unreliable for almost a decade:
        // https://bugs.chromium.org/p/chromium/issues/detail?id=344507
        if (!isFilterInPathActive()) {
            addDeferedEventListenerPoly("pageshow", window, () => {
                // The function of the pageshow refresh is to avoid showing wrong following states from the cache, and follow
                // stars are already force-updated in the base RoomList class. It's only needed for followed-cams because we
                // need to change the actual room cards shown in the roomlist itself, not just their follow stars.
                if (isFollowedCams()) {
                    this.handleSpaRouting()
                }
            })
        }
    }

    private interceptNavigationApplyCachedFilters(targetUrl: URL): void {
        // If the user clicks a link to the homepage, we have to apply the cached homepage filters URL before updating the
        // UrlState to the new url and triggering URLState listeners
        const targetUrlHasFilters = homepageFiltersCurrentlyApplied(UrlState.current.readStateFromURL(targetUrl))
        if (shouldShowHomepageFilters(targetUrl.pathname) && !targetUrlHasFilters) {
            // apply most recently selected filters url-state fom cache and navigate there
            targetUrl = getCachedHmpgFiltersUrl(targetUrl)
        }
        // navigateTo ensures that SPA link clicks jump back to the top of the page, and that the new scroll
        // position is associated with the new history entry
        UrlState.current.navigateTo(targetUrl)
    }

    private spaInterceptOnClick(event: MouseEvent, check_attribute = false): void {
        // First, attempt to get a valid HTMLAnchorElement from the event
        const anchorTarget = getEligibleTargetAnchor(event.target)
        if (anchorTarget === undefined) {
            return
        }
        // Don't intercept modifier-key clicks, easier than correctly handling them explicitly
        if (event.ctrlKey || event.metaKey || event.shiftKey) {
            return
        }
        // If this click intercept was triggered by the document-level listener, make sure the targeted
        // link is flagged with the appropriate attribute before handling as SPA navigation
        if (check_attribute && !anchorTarget.hasAttribute(ROOMLIST_NAV_ATTRIBUTE)) {
            return
        }
        const targetUrl = new URL(anchorTarget.href)
        // If the targetUrl is the root path and a homepage filter url is cached, navigate to that url instead
        this.interceptNavigationApplyCachedFilters(targetUrl)

        event.preventDefault()
        if (!isFilterInPathActive()) {
            this.handleSpaRouting()
        }
    }

    private getElementClassName(): string {
        const isPrivate = isPremium()
        return isCategoryPage() ? "category_page" : isPrivate ? "private_shows" : "main_roomlist"
    }

    private handleSpaRouting(): void {
        this.handleSharedUIUpdates()
        if (isPremium()) {
            this.handlePrivateCamsPage()
        } else if (isFollowedCams()) {
            this.handleFollowedCamsPage()
        } else {
            this.handleMainRoomlistPage()
        }
        newSpaLoad.fire()
    }

    /**
     * SHARED UI UPDATE METHODS (for UI/container elements common to every page type)
     */

    private handleSharedUIUpdates(): void {
        const gender = getCurrentGender()
        if (!isRoomRoomlistSpaActive() && !isBlockMetaDataActive()) {
            // Update shared page elements from the top down, starting with the document title
            window.document.title = getRoomlistPageTitle()
            const hashtag = getPageHashtag() ?? ""
            // Update Meta tags and Head hrefs.
            this.roomlistMetaUpdate?.updateTags(gender, isPremium(), hashtag)
        }
        // Keep CSS targeting classes on root container consistent
        this.element.className = this.getElementClassName()
        RoomlistRoot.addMainClassnames()
        // Ensure gender tabs highlight the correct gender and update tab link hrefs correctly
        if (this.genderTabs instanceof SubNavHeaderTabs) {
            this.genderTabs.renderTabs()
        }
        // Update the search input according to the URL params
        // Firing this event ensures not only that the value is set correctly, but that the input is
        // appropriately minimized/hidden depending on that value and search suggestions are reset.
        // Hence we fire it whether or not the urlKeywords have actually changed for this navigation.
        const urlKeywords = new URLSearchParams(window.location.search).get("keywords") ?? ""
        RoomlistSearchInput.searchKeywordValueUpdate.fire(urlKeywords)
        // Ensure other gendered links update to reflect the current gender
        genderFilterUpdateFromNav.fire(gender)
    }

    private static addMainClassnames(): void {
        const main = document.querySelector<HTMLDivElement>("#main")
        if (main === null) {
            return
        }
        const classnames = [
            (getRegionCategoryFilter() != null) ? "roomlist-category-region" : "",
            shouldShowHomepageFilters() ? "roomlist-filter-panel-enabled" : "",
            // Add other cases and classnames here...
        ]
        // Filter falsy (i.e. empty strings) before the join to avoid extra spaces in classname
        main.className = classnames.filter(Boolean).join(" ")
    }

    /**
     * "PAGE-LEVEL" RENDERING METHODS
     */

    private handleMainRoomlistPage(): void {
        if (shouldShowHomepageFilters()) {
            this.hideHashtagTicker()
            this.renderFilterPanel()
            // cache homepage filters in session storage here to prevent tag filter from resetting
            // when opening tag in another tab or manually through URL
            cacheHomepageFilters(true)
        } else {
            this.renderHashtagTicker()
            this.disposeFilterPanel()
        }
        this.disposeFollowedCamsLinks()
        this.renderMainRoomlist()
        this.disposeSecondaryRoomlist()
        this.disposeFollowRecommendations()
    }

    private handlePrivateCamsPage(): void {
        this.hideHashtagTicker()
        this.disposeFilterPanel()
        this.disposeFollowedCamsLinks()
        this.renderMainRoomlist()
        this.renderSecondaryRoomlist()
        this.disposeFollowRecommendations()
    }

    private handleFollowedCamsPage(): void {
        this.hideHashtagTicker()
        this.disposeFilterPanel()
        this.renderMainRoomlist()
        this.renderFollowedCamsLinks()  // Relies on the main roomlist for placement
        this.disposeSecondaryRoomlist()
        this.renderFollowRecommendations()
    }

    /**
     * "COMPONENT-LEVEL" RENDERING METHODS
     */

    private renderHashtagTicker(): void {
        const gender = getCurrentGender()
        const hashtag = getPageHashtag() ?? ""
        if (this.hashtagTicker === undefined) {
            const onTagClickHandler = (event: MouseEvent) => {
                this.spaInterceptOnClick(event)
            }
            this.element.prepend(<div>
                <HashtagTicker
                    onTagClick={onTagClickHandler}
                    classRef={(c: HashtagTicker) => { this.hashtagTicker = c }}
                />
                {!isHomepageFiltersActive() && <RemoveHashtag
                    onTagClick={onTagClickHandler}
                    classRef={(c: RemoveHashtag) => { this.removeHashtagLink = c }}
                />}
            </div>)
        }
        this.hashtagTicker?.setState({
            genders: (gender === Gender.All) ? [] : [gender],
        })
        this.removeHashtagLink?.setState({
            gender,
            hashtag: hashtag,
        })
        // Only need to show ticker, removeHashtagLink display is handled in updateState
        this.hashtagTicker?.showElement()
    }

    private hideHashtagTicker(): void {
        if (this.hashtagTicker === undefined) { return }
        this.hashtagTicker.hideElement()
        this.removeHashtagLink?.hideElement()
    }


    private renderFilterPanel(): void {
        // Create button and panel components if not already initialized
        if (this.hmpgFilterButton === undefined || this.filterPanel === undefined) {
            const buttonContainer = this._topSection.element.querySelector<HTMLUListElement>(".advanced-search-button-container")!

            this.filterLabelSection = new FilterLabelSection({
                onClearAll: this.clearHomepageFilters.bind(this),
                onRemoval: this.onFilterOptionClick.bind(this),
            })
            this.element.prepend(this.filterLabelSection.element)
            this.filterPanel = new HomepageFilterPanel({
                handleClose: () => {
                    this.hmpgFilterButton?.setActive(false)
                },
                onFilterOptionClick: this.onFilterOptionClick.bind(this),
            })
            this.hmpgFilterButton = new HomepageFilterButton({ filterPanel: this.filterPanel })
            this._mainContentDiv.element.before(this.filterPanel.element)
            buttonContainer.appendChild(this.hmpgFilterButton.element)
        }

        this.filterPanel.updateOptionsAndLabelsFromUrl()
        // When shown, want to restore button + panel state depending on cached value
        this.hmpgFilterButton.setActive(getFilterPanelOpenCached())
    }

    private onFilterOptionClick(): void {
        this.roomlistContainer?.resetPagination()
        cacheHomepageFilters()
        this.handleSpaRouting()
    }

    private clearHomepageFilters(): void {
        addPageAction("HmpgFiltersClearButtonClicked")
        this.filterPanel?.clearAll()
        UrlState.current.clearStateKeys(["keywords"], true)
        this.handleSpaRouting()
    }

    private disposeFilterPanel(): void {
        if (this.filterPanel === undefined) { return }
        this.filterPanel.element.remove()
        delete this.filterPanel
        this.hmpgFilterButton?.element.remove()
        delete this.hmpgFilterButton
        this.filterLabelSection?.element.remove()
        delete this.filterLabelSection
    }

    private renderFollowedCamsLinks(): void {
        this.disposeFollowedCamsLinks()
        this.followedOnlineOfflineTab = new FollowedOnlineOfflineTab({
            online: !isOfflineFollowed(),
            onFilterClick: (ev: MouseEvent) => this.spaInterceptOnClick(ev),
        })
        this.roomlistContainer?.element.before(this.followedOnlineOfflineTab.element)
    }

    private disposeFollowedCamsLinks(): void {
        if (this.followedOnlineOfflineTab === undefined) { return }
        this.followedOnlineOfflineTab.element.remove()
        delete this.followedOnlineOfflineTab
    }

    private renderMainRoomlist(): void {
        if (this.roomlistContainer === undefined) {
            const roomlistProps: IRoomlistContainerProps = {
                advancedSearchOptions: this.advancedSearchOptions,
                animate: this.props.animateThumbnails,
                showLocation: this.props.showLocation,
                appName: this.props.appName,
            }
            this.roomlistContainer = new HomepageRoomlistContainer(roomlistProps)
            this.addChild(this.roomlistContainer)
        }
        this.roomlistContainer.updateContainerFromUrl()
        // Check for a prefetch request on window, if found pass it through and then clear it so it's only used once
        // (No real need for a conditional check, if it's undefined then the delete does nothing)
        const prefetchPromise = window["prefetchPromise"]
        this.roomlistContainer?.updateRooms(prefetchPromise)
        delete window["prefetchPromise"]
    }

    private renderSecondaryRoomlist(): void {
        // This secondary roomlist container is specifically for the /spy-on-cams/ page
        if (this.secondaryContainer === undefined) {
            const roomlistProps: IRoomlistContainerProps = {
                advancedSearchOptions: this.advancedSearchOptions,
                animate: isRoomAnimationEnabled(),  // Use helper fxn instead of props value in case of change since page load
                showLocation: this.props.showLocation,
                pageParam: SECONDARY_PAGE_PARAM,
            }
            this.secondaryContainer = new HiddenCamsRoomlistContainer(roomlistProps)
            this.addChild(this.secondaryContainer)
        }
        this.secondaryContainer.updateContainerFromUrl()
        this.secondaryContainer.updateRooms()
    }

    private disposeSecondaryRoomlist(): void {
        if (this.secondaryContainer === undefined) { return }
        this.removeChild(this.secondaryContainer)
        this.secondaryContainer.dispose()
        delete this.secondaryContainer
    }

    private renderFollowRecommendations(): void {
        if (isRecommendedFollowRoomsActive()) {
            if (this.recommendations === undefined) {
                const recommendationProps: IFollowRecommendationsProps = {
                    animate: isRoomAnimationEnabled(),
                    showLocation: this.props.showLocation,
                    advancedSearchOptions: this.advancedSearchOptions,
                }
                this.recommendations = new FollowRecommendationsRoomlistContainer(recommendationProps)
                this.addChild(this.recommendations)
            }
        }
    }

    private disposeFollowRecommendations(): void {
        if (isRecommendedFollowRoomsActive()) {
            if (this.recommendations === undefined) { return }
            this.recommendations.dispose()
            this.removeChild(this.recommendations)
            delete this.recommendations
        }
    }

    /**
     * ROOM RELOAD TIMEOUT METHODS
     */

    private bindRoomRefreshTimers(): void {
        if (!isRoomRoomlistSpaActive()) {
            if (isPremium()) {
                RoomReload.scheduleImageRefresh(PREMIUM_REFRESH_SECONDS)
            } else {
                RoomReload.scheduleImageRefresh(spaPageContext.getState().refreshFrequency ?? this.props.refreshFrequency)
            }
        }
        // Because the SPA only loads info such as active split tests once at first pageload, we want a
        // mechanism to occasionally do a full page refresh. To avoid disrupting the user's browsing, we
        // check at each 15 minute interval whether the user has interacted with the document in the past
        // ONE minute, and if not we perform a full page reload instead of just a roomlist fetch.
        addEventListenerMultiPoly(
            ["click", "focusin", "keydown", "pointerdown", "pointermove", "scroll", "visibilitychange"],
            document,
            () => this.setUserActivityTimestamp(),
        )

        window.setInterval(() => this.inactiveRefreshCheck(), SPA_RELOAD_MILLISECONDS)
    }


    private inactiveRefreshCheck(): void {
        if (isRoomRoomlistSpaEligiblePage() && UrlState.current.state.room !== undefined) {
            // Prevent reload or refresh if not a room page type and experiment is active.
            return
        }
        // Avoid refreshing in background if user hasn't actually interacted with document at all yet
        if (this.lastUserActivityTimestamp === 0) {
            return
        }
        // If the user has been inactive for long enough, do a full reload, otherwise reload room cards via API fetch
        if (Date.now() - this.lastUserActivityTimestamp >= INACTIVE_THRESHOLD_MILLISECONDS) {
            window.location.reload()
        } else {
            this.roomlistContainer?.handleRoomRefresh()
            this.secondaryContainer?.handleRoomRefresh()
        }
    }

    private setUserActivityTimestamp(): void {
        this.lastUserActivityTimestamp = Date.now()
    }

    hideAllContent(): void {
        this.filterPanel?.hideElement()
        this.hmpgFilterButton?.hideElement()
        this._mainContentDiv.hideElement()
    }

    showAllContent(): void {
        this.filterPanel?.showElement()
        this.hmpgFilterButton?.showElement()
        this._mainContentDiv.showElement()
    }
}
