import { ALL_NO_PAGE, ShowType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { titleCase } from "@multimediallc/web-utils"
import { roomLoaded } from "../../../common/context"
import { ListenerGroup } from "../../../common/events"
import { Gender, GenderNameToSymbolMap } from "../../../common/genders"
import { RoomStatus } from "../../../common/roomStatus"
import { i18n } from "../../../common/translation"
import { dom, Fragment } from "../../../common/tsxrender/dom"
import { pageContext } from "../../interfaces/context";
import { currentSiteSettings, defaultSiteSettings } from "../../siteSettings"
import { getRoomlistPageTitle } from "../roomlist/spaHelpers"

interface ISPAMetaDataProps {
    /**
     * A shared mix of properties used to determine what meta data should be shown. Currently represents roomlist and room type pages.
     * Room Exclusive Properties: roomName, roomSubject, roomOnline, twitterImage
     * Roomlist Exclusive Properties: premium, hashtag
     * Shared Properties: gender, defaultImage
     *
     * While roomName is not undefined, only room exclusive and shared properties are considered valid. When undefined, only roomlist and shared
     * properties will be considered valid.
     */
    gender: Gender  // Can either represent the gender filter of the roomlist or the broadcaster's gender
    premium: boolean  // Represents if the roomlist is filtered on premium type cams.
    hashtag: string  // Represents the hashtag a roomlist is filtered on.
    roomName?: string
    roomSubject?: string
    roomOnline?: boolean
    twitterImage?: string
    defaultImage: string  // A default image we will use if there is no user provided image
}

const OPEN_GRAPH_IMAGE_DIMENSIONS_DEFAULT = 1000
const OPEN_GRAPH_IMAGE_SOURCE_DEFAULT = `${STATIC_URL_ROOT}image/logo-square.png`

const META_DATA_MARKER_SUFFIX = "marker"
const MARKER_START_VALUE = "start"
const MARKER_END_VALUE = "end"

export class SpaMetaUpdate {
    /**
     * Class that handles updating meta data on SPA navigation or URL state change.
     * Does this by looking at matching comment blocks and replacing the content
     * contained between the comment block with a new fragment. When making changes to
     * the meta data, please refer to the relevant methods below and compare the changes
     * against the relevant templates and matching template blocks. Named blocks are
     * mentioned below within the fragment creation method.
     */
    private props: ISPAMetaDataProps
    private listenerGroup: ListenerGroup = new ListenerGroup()

    constructor() {
        this.bindChangesOnListener()
    }

    private bindChangesOnListener(): void {
        const replaceMetaContent = (): void => {
            this.replaceContentBetweenMarkers(META_DATA_MARKER_SUFFIX, this.createMetaDataFragment(this.props))
        }

        UrlState.current.listenGlobal(ALL_NO_PAGE, (state) => {
            if (state.room === undefined) {
                this.props = {
                    ...this.props,
                    gender: (state.genders !== undefined && state.genders.length > 0) ? state.genders[0] : Gender.All,
                    premium: state.showType === ShowType.PRIVATE,
                    hashtag: (state.tags !== undefined && state.tags.length > 0) ? state.tags[0] : "",
                    roomName: undefined,
                    defaultImage: OPEN_GRAPH_IMAGE_SOURCE_DEFAULT,
                    twitterImage: undefined,
                }
                replaceMetaContent()
            }
        })

        roomLoaded.listen((context) => {
            this.listenerGroup.removeAll()
            const roomOnline = context.dossier.roomStatus !== RoomStatus.Offline
            const roomName = context.dossier.room

            this.props = {
                ...this.props,
                gender: GenderNameToSymbolMap.get(context.dossier.roomGender) ?? Gender.All,
                roomName: roomName,
                roomSubject: context.dossier.roomTitle,
                roomOnline: roomOnline,
                defaultImage: this.getOGMetaImage(roomOnline, roomName),
                twitterImage: context.dossier.summaryCardImage,
            }
            replaceMetaContent()

            context.chatConnection.event.titleChange.listen((title: string) => {
                this.props = {
                    ...this.props,
                    roomSubject: title,
                }
                replaceMetaContent()
            }).addTo(this.listenerGroup)
        })
    }

    private getNewURLWithCurrentPathAndSearch(absoluteURL: string): string {
        // Returns absoluteURL with current pathname and params
        const newUrl = new URL(absoluteURL)
        newUrl.pathname = window.location.pathname
        newUrl.search = window.location.search
        return newUrl.toString()
    }

    /**
     * Meta Links Section -- Start
     * Intended to contain all meta data links from language links, RSS feed links, canonical links, or
     * any other alternate link meta data.
     * */
    private createLanguageLinks(): HTMLLinkElement[] {
        const newLangParams = new URLSearchParams(window.location.search)
        const currentLangUrl = new URL(window.location.href)
        return [
            <link rel="alternate" hrefLang="x-default" href={currentLangUrl.toString()} />,
            ...window.siteLanguages.map((languageCode) => {
                newLangParams.set("language", languageCode)
                currentLangUrl.search = newLangParams.toString()
                return <link rel="alternate" hrefLang={languageCode} href={currentLangUrl.toString()} />
            }),
        ]
    }

    private getRssLinkTitle(gender: Gender, premium: boolean): string {
        let camCategory = i18n.featuredText
        const siteName = titleCase(currentSiteSettings.siteName)
        if (premium) {
            camCategory = i18n.premiumShowsCaps
        } else if (gender === Gender.Female) {
            camCategory = i18n.femaleText
        } else if (gender === Gender.Male) {
            camCategory = i18n.maleText
        } else if (gender === Gender.Couple) {
            camCategory = i18n.coupleText
        } else if (gender === Gender.Trans) {
            camCategory = i18n.transText
        }
        return i18n.rssLinkTitle(camCategory, siteName)
    }

    private getRssLinkHref(gender: Gender, premium: boolean): string {
        const newParams = new URLSearchParams()
        if (gender !== Gender.All) {
            newParams.set("gender", gender)
        }
        if (premium) {
            newParams.set("premium", "1")
        }
        return `/feed/latest/?${newParams.toString()}`
    }

    private createRssLinks(gender: Gender, premium: boolean, roomName?: string): HTMLLinkElement[] {
        const rssLinkElements = []
        if (roomName === undefined) {
            if (premium || gender === Gender.All) {
                const premiumRssLink = <link rel="alternate" type="application/rss+xml" href={this.getRssLinkHref(gender, premium)} title={this.getRssLinkTitle(gender, premium)} />
                rssLinkElements.push(premiumRssLink)
            }
            if (gender !== Gender.All) {
                const genderLink = <link rel="alternate" type="application/rss+xml" href={this.getRssLinkHref(gender, false)} title={this.getRssLinkTitle(gender, false)} />
                rssLinkElements.push(genderLink)
            }
        }
        return rssLinkElements
    }
    /** Meta Links Section -- End */

    /**
     * General Meta Data Section -- Start
     * Intended to handle all other general meta data, such as descriptions, keywords, rating, and document title.
     */
    private roomlistMetaDescription(gender: Gender, premium: boolean): string {
        const descriptionString: string[] = []
        const domainCap = titleCase(window.location.hostname)
        if (gender === Gender.Female) {
            descriptionString.push(i18n.metaDescriptionFemale(domainCap))
        } else if (gender === Gender.Male) {
            descriptionString.push(i18n.metaDescriptionMale(domainCap))
        } else if (gender === Gender.Couple) {
            descriptionString.push(i18n.metaDescriptionCouples(domainCap))
        } else if (gender === Gender.Trans || gender === Gender.OldTrans) {
            descriptionString.push(i18n.metaDescriptionTrans(domainCap))
        } else {
            descriptionString.push(i18n.metaDescriptionDefault)
        }
        if (premium) {
            descriptionString.push(i18n.metaDescriptionPremium)
        }
        return descriptionString.join("\n")
    }

    private getMetaDescription(gender: Gender, premium: boolean, roomName?: string): string {
        if (roomName !== undefined) {
            return i18n.metaDescriptionSiteDefault
        }
        return this.roomlistMetaDescription(gender, premium)
    }

    private getMetaKeywords(gender: Gender, premium: boolean, hashtag: string, roomName?: string): string {
        if (roomName !== undefined) {
            return i18n.metaKeywordsSiteDefault
        }

        const keywordString: string[] = []
        if (hashtag !== "") {
            keywordString.push(i18n.metaKeywordsHashtag(hashtag))
        }
        if (gender === Gender.Female) {
            keywordString.push(i18n.metaKeywordsFemale)
        } else if (gender === Gender.Male) {
            keywordString.push(i18n.metaKeywordsMale)
        } else if (gender === Gender.Couple) {
            keywordString.push(i18n.metaKeywordsCouple)
        } else if (gender === Gender.Trans || gender === Gender.OldTrans) {
            keywordString.push(i18n.metaKeywordsTrans)
        } else if (!premium) {
            keywordString.push(i18n.metaKeywordsDefault)
        }
        if (premium) {
            keywordString.push(i18n.metaKeywordsPremium)
        }
        return keywordString.join(" ")
    }

    private getDocumentTitle(roomName?: string): HTMLTitleElement {
        let titleText
        if (roomName !== undefined) {
            titleText = i18n.documentTitle(roomName, currentSiteSettings.siteName)
        } else {
            titleText = getRoomlistPageTitle()
        }
        return <title>{titleText}</title>
    }
    /** General Meta Data Section -- End */

    /**
     * Open Graph and Twitter Meta Data Section -- Start
     * Intended to handle any meta data pertaining to Open Graph or Twitter.
     */
    private roomlistOGMetaTitle(gender: Gender, premium: boolean, hashtag: string): string {
        let titleString = ""
        const siteName = titleCase(currentSiteSettings.siteName)
        if (premium) {
            titleString = i18n.metaTitlePremium(siteName)
        } else if (hashtag !== "") {
            titleString = i18n.metaTitleHashtag(siteName, titleCase(hashtag))
        } else if (gender === Gender.Female) {
            titleString = i18n.metaTitleFemale(siteName)
        } else if (gender === Gender.Male) {
            titleString = i18n.metaTitleMale(siteName)
        } else if (gender === Gender.Couple) {
            titleString = i18n.metaTitleCouples(siteName)
        } else if (gender === Gender.Trans || gender === Gender.OldTrans) {
            titleString = i18n.metaTitleTrans(siteName)
        } else {
            titleString = i18n.metaTitleDefault(siteName)
        }
        return titleString
    }

    private getOGMetaTitle(gender: Gender, premium: boolean, hashtag: string, roomName?: string): string {
        if (roomName !== undefined) {
            const roomNameTitleCase = titleCase(roomName)
            return i18n.roomMetaContentCardTitle(roomNameTitleCase)
        }

        return this.roomlistOGMetaTitle(gender, premium, hashtag)
    }

    private roomlistOGMetaDescription(gender: Gender, premium: boolean, hashtag: string): string {
        let descriptionString = ""

        if (premium) {
            descriptionString = i18n.metaContentCardDescriptionPremium
        } else if (hashtag !== "") {
            descriptionString = i18n.metaContentCardDescriptionHashtag(titleCase(hashtag))
        } else if (gender === Gender.Female) {
            descriptionString = i18n.metaContentCardDescriptionFemale
        } else if (gender === Gender.Male) {
            descriptionString = i18n.metaContentCardDescriptionMale
        } else if (gender === Gender.Couple) {
            descriptionString = i18n.metaContentCardDescriptionCouples
        } else if (gender === Gender.Trans || gender === Gender.OldTrans) {
            descriptionString = i18n.metaContentCardDescriptionTrans
        } else {
            descriptionString = i18n.metaContentCardDescriptionDefault
        }
        return descriptionString
    }

    private getOGMetaDescription(gender: Gender, premium: boolean, hashtag: string, roomName?: string, roomSubject?: string): string {
        if (roomName !== undefined) {
            return roomSubject ?? ""
        }
        return this.roomlistOGMetaDescription(gender, premium, hashtag)
    }

    private getOGMetaImage(roomOnline: boolean, roomName?: string): string {
        if (roomName !== undefined && roomOnline) {
            return `${currentSiteSettings.jpegRoomImgUrl}ri/${roomName}.jpg?${Math.floor(new Date().getTime() / 30000)}`
        } else {
            return OPEN_GRAPH_IMAGE_SOURCE_DEFAULT
        }
    }

    private getTwitterImage(defaultImage: string, twitterImage?: string): DocumentFragment {
        const validTwitterImage = twitterImage !== undefined && twitterImage !== ""
        const image = validTwitterImage ? twitterImage : defaultImage
        return <Fragment>
            <meta name="twitter:card" content={validTwitterImage ? "summary_large_image" : "summary"} />
            <meta name="twitter:image" content={image} />
        </Fragment>
    }
    /** Open Graph and Twitter Meta Data Section -- End */

    private createMetaDataFragment(metaDataProps: ISPAMetaDataProps): DocumentFragment {
        /**
         * Creates a meta data fragment intended to replace the content between the prefixed "META_DATA" comment.
         */
        const { gender, premium, hashtag, roomName, roomOnline, roomSubject, twitterImage, defaultImage } = metaDataProps
        const baseDomain = this.getNewURLWithCurrentPathAndSearch(`https://${defaultSiteSettings.CBAlias}/`)
        const useDefaultValues = roomOnline !== true
        return <Fragment>
            {/* In base template, represents the portion in "meta_links" block */}
            <link rel="canonical" href={this.getNewURLWithCurrentPathAndSearch(`https://${defaultSiteSettings.CBAlias}/`)}></link>
            {this.createLanguageLinks()}
            {!currentSiteSettings.isWhiteLabel && !pageContext.current.removeMobileSubdomain && <link rel="alternate" media="only screen and (max-width: 640px)" href={this.getNewURLWithCurrentPathAndSearch(`https://m.${defaultSiteSettings.CBAlias}/`)} />}
            {roomName === undefined && this.createRssLinks(gender, premium)}

            {/* In base template, represents the portion in "metadata" block */}
            <meta name="msvalidate.01" content="7198395454E8014E0B82A087701FE16B" />
            {!currentSiteSettings.isWhiteLabel ?
                <Fragment>
                    <meta name="description" content={this.getMetaDescription(gender, premium, roomName)}/>
                    <meta name="keywords" content={this.getMetaKeywords(gender, premium, hashtag, roomName)}/>
                    <meta name="Rating" content="mature"/>
                    <meta name="RATING" content="RTA-5042-1996-1400-1577-RTA"/>
                </Fragment> : Boolean(currentSiteSettings.metaDescription) && <meta name="description" content={currentSiteSettings.metaDescription}/>
            }

            {this.getDocumentTitle(roomName)}

            {/* In base template, represents the portion in "meta_content_cards" block  */}
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={this.getOGMetaTitle(gender, premium, hashtag, roomName)} />
            <meta property="og:description" content={this.getOGMetaDescription(gender, premium, hashtag, roomName, roomSubject)} />
            <meta property="og:image" content={useDefaultValues ? OPEN_GRAPH_IMAGE_SOURCE_DEFAULT : this.getOGMetaImage(roomOnline, roomName)} />
            <meta property="og:image:height" content={`${useDefaultValues ? OPEN_GRAPH_IMAGE_DIMENSIONS_DEFAULT : 270}`} />
            <meta property="og:image:width" content={`${useDefaultValues ? OPEN_GRAPH_IMAGE_DIMENSIONS_DEFAULT : 360}`} />
            <meta property="og:url" content={baseDomain} />
            {this.getTwitterImage(defaultImage, twitterImage)}
            {roomName !== undefined && <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />}
        </Fragment>
    }

    private replaceContentBetweenMarkers(dataAttributeSuffix: string, fragment: DocumentFragment): void {
        /**
         * We will search the document head for the matching meta tag with data attribute data-{dataAttributeSuffix} and remove any content
         * contained between <meta data-{dataAttributeSuffix}={MARKER_START_VALUE} /> and <meta data-{dataAttributeSuffix}>={MARKER_END_VALUE} />.
         * This content is replaced with the provided fragment.
         * @param dataAttributeSuffix - The data attribute suffix we want to search for matching meta tags of and replace the content between
         * @param fragment - The updated content that will replace the existing meta data found in the previously identified block
         */
        const isMarkerWithDataAttribute = (el: Element, value: string): boolean => {
            return el.nodeName === "META" && el.getAttribute(`data-${META_DATA_MARKER_SUFFIX}`) === value
        }

        const removeElements = (elements: Element[]): void => {
            for (const el of elements) {
                document.head.removeChild(el)
            }
        }

        let startingEl: Element | undefined
        let endingElFound = false
        const elementList: Element[] = []
        for (const childEl of document.head.children) {
            if (isMarkerWithDataAttribute(childEl, MARKER_END_VALUE)) {
                endingElFound = true
                break
            }

            if (startingEl !== undefined) {
                elementList.push(childEl)
            }

            if (isMarkerWithDataAttribute(childEl, MARKER_START_VALUE)) {
                startingEl = childEl
            }
        }

        if (startingEl !== undefined && endingElFound) {
            removeElements(elementList)
            startingEl.after(fragment)
        } else {
            error("Unable to update meta data due to malformed meta tags", {
                "dataAttribute": dataAttributeSuffix,
                "startingElementFound": startingEl !== undefined,
                "endElementFound": endingElFound,
            })
        }
    }
}
